
import { computed, defineComponent } from '@nuxtjs/composition-api'
import ContentBuilderSlideout from '~/components/feature/ContentBuilder/ContentBuilderSlideout.vue'
import ContentBuilderAccordion from '~/components/feature/ContentBuilder/ContentBuilderAccordion.vue'
import ContentBuilderTextbox from '~/components/feature/ContentBuilder/ContentBuilderTextbox.vue'
import ContentBuilderVideo from '~/components/feature/ContentBuilder/ContentBuilderVideo.vue'
import { IContentBuilder } from '~/ts/interfaces/contentBuilder-interfaces'

export default defineComponent({
  name: 'ContentBuilder',
  components: { ContentBuilderVideo, ContentBuilderTextbox, ContentBuilderAccordion, ContentBuilderSlideout },
  props: {
    /**
     * Content for the Builder
     */
    content: {
      type: Array as () => IContentBuilder[],
      default: () => []
    }
  },
  setup (props) {
    const prepareContent = computed(() => {
      let groupCache
      const content = [] as any[]

      props.content.forEach((val) => {
        switch (val.type) {
          case 'info_video':
            content.push(val)
            break
          case 'info_textbox':
            if (!val.variant || val.variant === 'regular') {
              content.push(val)
            } else if (val.variant === 'slideout' && !val.group) {
              content.push({ type: 'slideout', content: val })
            } else if (val.variant === 'slideout' && val.group && groupCache !== val.group) {
              groupCache = val.group
              const accordions = props.content.filter(s => s.variant === 'slideout' && s.group === val.group)
              content.push({ type: 'accordion', content: accordions })
            }
        }
      })

      return content
    })

    return {
      prepareContent
    }
  }
})
