
import { computed, defineComponent, useContext, useStore } from '@nuxtjs/composition-api'
import GeneralButton from '~/components/base/GeneralButton/GeneralButton.vue'

export default defineComponent({
  name: 'InformationCardEntry',
  components: { GeneralButton },
  props: {
    /**
     * ID of the Card
     */
    id: {
      type: String,
      default: ''
    },
    /**
     * Mark the final card
     */
    lastEntry: {
      type: Boolean,
      default: false
    },
    /**
     * Information Card content
     */
    entry: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const { i18n } = useContext()

    const userDetails = computed(() => store.getters['access/getUserDetails'])

    const userName = computed(() => {
      if (userDetails.value.name?.startsWith('hb.')) {
        return i18n.t('hb.you')
      } else if (userDetails.value.company?.provider?.identifier === 'haendlerbund') {
        return i18n.t('hb.you')
      } else if (userDetails.value.firstName?.length || userDetails.value.name) {
        return userDetails.value.firstName || userDetails.value.name
      } else {
        return i18n.t('hb.you')
      }
    })

    const hasMedia = computed(() => {
      return props.entry.video?.length || props.entry.image?.length
    })

    const setCard = () => {
      let response
      if (props.lastEntry && !props.entry.link) {
        response = 'finalCard'
      } else if (!props.lastEntry) {
        response = 'nextCard'
      }

      emit(response)
    }

    return {
      userDetails,
      userName,
      hasMedia,
      setCard
    }
  }
})
