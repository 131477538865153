<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'WebSocketHandler',
  computed: {
    ...mapGetters({
      socketResponses: 'socket/getQueue'
    })
  },
  watch: {
    socketResponses: {
      handler () {
        this.socketResponseHandler()
      },
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('socket/getSocketData')
  },
  methods: {
    clearQueueEntry (key, time = 1000) {
      setTimeout(() => {
        this.$store.dispatch('socket/deleteQueueEntry', key)
      }, time)
    },

    socketResponseHandler () {
      Object.keys(this.socketResponses).forEach((key) => {
        const response = this.socketResponses[key]
        const changeContext = response.message

        this.$nuxt.$emit('websocket.' + response.action, response)

        switch (response.action) {
          case 'project.status.change' :
            if (changeContext === 'open') {
              this.socketProjectChangeOpen(key)
            } else {
              this.socketProjectChangeClose(key)
            }
            break
          case 'system.component.delete':
            this.socketComponentDelete(key)
            break
          case 'system.component.update':
            this.socketComponentUpdate(key)
            break
          case 'system.component.create':
            this.socketComponentCreate(key)
            break
          case 'system.component.screenshot.create':
            this.socketComponentCreateScreenshot(key, response.attributes)
            break

          case 'component.finder.found':
            this.componentFinderFound(response.attributes)
            break
          case 'component.finder.finish':
            this.componentFinderFinish(response.attributes)
            break
          case 'system.run.full.start':
            this.socketNewFullRun()
            break
          case 'system.run.full.finish':
            this.socketFinishRun(key)
            break
          case 'system.run.full.finish.first':
            this.socketFinishRun(key)
            break
        }
      })
    },

    socketFinishRun (key) {
      const { pid } = this.$route.params
      if (pid !== key) return
      this.$store.dispatch('testrunner/setFirstRun', false)
    },

    socketProjectChangeOpen: debounce(function (key) {
      const { sid, pid } = this.$route.params
      this.$store.dispatch('scores/collectSystemScores', { sid, update: true })
      this.$store.dispatch('incidents/collectProjectIncidents', { pid: key, update: true })
      if (pid !== key) return
      this.socketOpenIncident(key)
    }, 8000),

    socketProjectChangeClose: debounce(function (key) {
      const { sid, pid } = this.$route.params
      this.$store.dispatch('scores/collectSystemScores', { sid, update: true })
      this.$store.dispatch('incidents/collectProjectIncidents', { pid: key, update: true })
      if (pid !== key) return
      this.socketClosedIncident(key)
    }, 8000),

    async socketOpenIncident (key) {
      const responses = this.socketResponses[key]
      const incidentId = responses && responses.attributes ? JSON.parse(responses.attributes).last_incident_id : false
      if (incidentId) {
        try {
          const incidentRepo = await this.$apiClient.getRepository('incident')
          const message = await incidentRepo.find(key, incidentId)
          this.$notify({
            group: 'socketNotificationAreaIncidents',
            type: 'error',
            title: this.$t('projectStatusChange.open'),
            text: message.details.message || this.$t('projectStatusChange.openSentence'),
            data: incidentId,
            duration: -1,
            ignoreDuplicates: true
          })
        } catch (error) {
          this.$sentry.captureException(error)
        }
      }
      this.clearQueueEntry(key)
    },

    socketClosedIncident (key) {
      this.$notify({
        group: 'socketNotificationArea',
        type: 'success',
        title: this.$t('projectStatusChange.close'),
        text: this.$t('projectStatusChange.closeSentence'),
        duration: 4000,
        ignoreDuplicates: true
      })
      this.clearQueueEntry(key)
    },

    socketComponentDelete (key) {
      this.notification('componentDeleted', key)
    },

    socketComponentUpdate (key) {
      this.notification('componentUpdated', key)
    },

    socketComponentCreate (key) {
      this.notification('componentCreated', key)
    },

    socketComponentCreateScreenshot (key, attributes) {
      const { sid } = this.$route.params
      const systemId = JSON.parse(attributes).system_id
      this.notification('screenshotCreated', key)
      if (parseInt(sid) === parseInt(systemId)) {
        this.$store.dispatch('screenshots/collect', sid)
      }
    },

    socketNewFullRun () {
      const sid = this.$route.params.sid
      if (!sid) return
      this.$store.dispatch('testrunner/initiateRunner', sid)
    },

    componentFinderFound (payload) {
      const payloadData = JSON.parse(payload)
      this.$store.dispatch('componentFinder/setRunningFinder', payloadData.run_id)
      this.$store.dispatch('componentFinder/setComponentFinder', {
        id: payloadData.run_id,
        path: payloadData.path,
        componentIdentifier: payloadData.component_identifier
      })
    },

    componentFinderFinish (payload) {
      const payloadData = JSON.parse(payload)
      this.$store.dispatch('componentFinder/removeRunningFinder', payloadData.run_id)
    },

    notification (title, key, duration = 4000, type = 'info') {
      this.$notify({
        group: 'socketNotificationArea',
        type,
        title: this.$t(title),
        text: this.socketResponses[key].message || '',
        duration,
        ignoreDuplicates: true
      })
      this.clearQueueEntry(key)
    }
  },
  render: () => null
}
</script>
