import mitt from 'mitt'
import axios from 'axios'
import { computed, useContext, useStore } from '@nuxtjs/composition-api'
import useRoleManagement from '@/composeables/roleManagement/roleManagement'
import { debugEcho } from '~/utils/debugTools'

export default function () {
  window.mitt = window.mitt || mitt()
  const { i18n } = useContext()
  const store = useStore()

  const whitelabelConfig = computed(() => store.getters['whitelabel/getConfig'])

  const { realPartnerType } = useRoleManagement()

  const cleverBridgeIframeUrl = async () => {
    try {
      const upsellUrl = process.env.UPSELL_LINK || ''

      if (upsellUrl) {
        const uUrl = `${window.location.origin}/${upsellUrl}`
        debugEcho('uUrl', uUrl)
        const { data } = await axios.get(uUrl)
        if (!data?.data) throw new Error('No data')
        return data.data.url
      }
    } catch (e) { console.error(e) }
  }

  const triggerUpsellContent = async (
    contentId: string,
    labelId = 'business',
    titleId = 'youHaveDiscoveredANewFeature'
  ) => {
    const iframeUrl = realPartnerType.value === 'standaloneRetail'
      ? await cleverBridgeIframeUrl()
      : ''
    const partnerShopUrl = realPartnerType.value !== 'standaloneRetail'
      ? whitelabelConfig.value.storeUrl || ''
      : ''

    debugEcho('title', titleId)
    window.mitt.emit('tsxContentScreenConfig', {
      contentId,
      type: 'marketing',
      language: i18n.locale,
      label: i18n.t(labelId),
      title: i18n.t(titleId),
      iframeUrl,
      partnerShopUrl,
      isPartner: ['partner', 'standalonePartner'].includes(realPartnerType.value),
      brandName: whitelabelConfig.value.companyName
    })
  }

  const triggerCleverbridgeContent = async () => {
    try {
      const contentUrl = await cleverBridgeIframeUrl()
      debugEcho('CONTENTURL', contentUrl)

      if (contentUrl) {
        window.mitt.emit('tsxContentScreenConfig', {
          contentUrl,
          type: 'content',
          title: i18n.t('upgradeAccount')
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    triggerUpsellContent,
    triggerCleverbridgeContent,
    cleverBridgeIframeUrl
  }
}
