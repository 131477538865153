import { computed, useContext, useStore } from '@nuxtjs/composition-api'

export default function () {
  const { i18n, $apiClient } = useContext()
  const store = useStore()

  const userDetails = computed(() => store.getters['access/getUserDetails'])

  const changeLanguage = async (language: string) => {
    try {
      $apiClient.setLanguage(language)
      const clusterRepo = await $apiClient.getRepositoryCollection().then(res => res.getUserRepository())
      await clusterRepo.setPreferredLanguage(process.env.appType, userDetails.value.masterId, {
        language
      })

      i18n.setLocaleCookie(language)
      i18n.locale = language
      // store.dispatch('ui/triggerRerenderUi')
    } catch (error) {
      console.error(error)
    }
  }

  return {
    changeLanguage
  }
}
