
import { defineComponent } from '@nuxtjs/composition-api'
import IconCaret from '~/components/pure/IconCaret/IconCaret.vue'
import HeightExpander from '~/components/base/HeightExpander/HeightExpander.vue'
import Richtext from '~/components/pure/Richtext/Richtext.vue'

export default defineComponent({
  name: 'ContentBuilderSlideout',
  components: { Richtext, HeightExpander, IconCaret },
  props: {
    /**
     * Headline to trigger the slideout
     */
    headline: {
      type: String,
      default: ''
    },
    /**
     * Bodytext
     */
    body: {
      type: String,
      default: ''
    },
    /**
     * State from the Slideout
     */
    openState: {
      type: Boolean,
      default: false
    }
  }
})
