
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ContentBuilderVideo',
  props: {
    /**
     * Embed Code of the video
     */
    embed: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const embedSrc = computed(() => {
      let url
      if (props.embed.includes('youtube')) {
        if (props.embed.includes('watch')) {
          const id = props.embed.split('=')[1]
          url = `https://www.youtube-nocookie.com/embed/${id}`
        } else {
          url = props.embed
        }
      }
      return url
    })

    return {
      embedSrc
    }
  }
})
