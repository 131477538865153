
import { defineComponent, ref } from '@nuxtjs/composition-api'
import ContentBuilderSlideout from '~/components/feature/ContentBuilder/ContentBuilderSlideout.vue'

export default defineComponent({
  name: 'ContentBuilderAccordion',
  components: { ContentBuilderSlideout },
  props: {
    /**
     * Content for Accordions
     */
    accordions: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const openIndex = ref(0)

    const open = (id) => {
      openIndex.value = openIndex.value !== id ? id : null
    }

    return {
      openIndex,
      open
    }
  }
})
