
import { defineComponent } from '@nuxtjs/composition-api'
import Richtext from '~/components/pure/Richtext/Richtext.vue'

export default defineComponent({
  name: 'ContentBuilderTextbox',
  components: { Richtext },
  props: {
    /**
     * Headline
     */
    headline: {
      type: String,
      default: ''
    },
    /**
     * Bodytext
     */
    body: {
      type: String,
      default: ''
    }
  }
})
