
import { computed, onMounted, ref, useRoute, useStore, watch } from '@nuxtjs/composition-api'
import axios from 'axios'
import IconCancel from '~/components/pure/IconCancel/IconCancel.vue'
import ContentBuilder from '~/components/feature/ContentBuilder/ContentBuilder.vue'

export default {
  name: 'SecondScreen',
  components: { ContentBuilder, IconCancel },
  props: {
    /**
     * @ignore
     */
    storybook: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()

    const screenState = ref(false)
    const responseMessage = ref<null | any>(null)
    const requestUrl = ref<null | string>(null)
    const isLoaded = ref(false)

    const screenOpen = computed(() => store.getters['ui/getSecondScreenState'])
    const screenUrl = computed(() => store.getters['ui/getSecondScreenUrl'])
    const message = computed(() => store.getters['secondScreen/getMessage'])
    const title = computed(() => responseMessage.value?.data.headline_infoFrame || responseMessage.value?.data.title || '')
    const blocks = computed(() => responseMessage.value?.data.blocks || [])
    const closeScreen = () => store.dispatch('ui/closeSecondScreen')

    /**
     * Activate the Screen after content is loaded
     */
    const checkResponse = () => {
      if (screenUrl.value.length || props.storybook) {
        const messageEntry = message.value(screenUrl.value)
        if (messageEntry) {
          isLoaded.value = true
          responseMessage.value = messageEntry
        } else {
          responseMessage.value = null
          getResponse()
        }
      }
    }

    /**
     * Load the external content
     */
    const getResponse = async () => {
      try {
        const data = await axios.get(screenUrl.value)
        responseMessage.value = { data: data.data }
        isLoaded.value = true
        store.dispatch('secondScreen/addMessage', {
          url: screenUrl.value,
          data: data.data
        })
      } catch (error) {
        console.error(error)
      }
    }

    /**
     * Set screen on the initial state if the route will changed
     */
    watch(() => route.value, () => {
      store.dispatch('ui/clearSecondscreenUrl')
      if (screenOpen.value) {
        store.dispatch('ui/closeSecondScreen')
        isLoaded.value = false
      }
    })

    /**
     * Load content if the screen is open
     */
    watch(() => screenOpen.value, () => checkResponse())

    onMounted(() => {
      if (props.storybook) {
        isLoaded.value = true
        checkResponse()
      }
    })

    return {
      screenState,
      responseMessage,
      requestUrl,
      isLoaded,
      screenOpen,
      screenUrl,
      message,
      title,
      blocks,
      checkResponse,
      closeScreen
    }
  }
}
