var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('portal',{attrs:{"to":_vm.target}},[_c('transition',{attrs:{"name":"visible"}},[(_vm.openState)?_c('div',{staticClass:"fixed w-full top-0 left-0 flex min-h-screen",class:[
        _vm.screenColor,
        _vm.middle ? 'items-center' : 'items-start',
        _vm.scrollable ? 'overflow-y-scroll' : '',
      ],staticStyle:{"z-index":"1000"}},[_c('div',{staticClass:"w-full",class:[
          _vm.scrollable ? 'overflow-y-scroll scrolling-touch py-20p' : 'flex justify-center items-center',
          _vm.desktopCentered ? 'md:flex justify-center items-center' : '',
        ]},[_c('div',{staticClass:"relative mx-auto",class:[_vm.modalColor, _vm.modalStyle, _vm.modalWidth]},[(!_vm.customClose)?_c('div',{staticClass:"absolute right-0 top-0 p-2 cursor-pointer z-10",on:{"click":function($event){return _vm.$emit('closeModal')}}},[_c('IconCancel',{staticClass:"h-4 w-4",attrs:{"color":"#383737"}})],1):_vm._e(),_vm._v(" "),_vm._t("default")],2)])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }