
import { computed, defineComponent } from '@nuxtjs/composition-api'

type ColorOptions = 'base' | 'caution' | 'carefree' | 'transparent' | 'grayed' | 'lightGray' | 'ghost' | 'gray' | 'info' | 'marketing'

/**
 * Generate an universal button for internal and external links, also for events
 */
export default defineComponent({
  name: 'GeneralButton',
  props: {
    /**
     * Freely selectable day, for events
     * @values  a, div, span
     */
    customTag: {
      type: String,
      default: 'a'
    },
    /**
     * If a path exists. the button will be set to Link mode
     */
    linkPath: {
      type: String,
      default: null
    },
    /**
     * Set exact param for interal links
     */
    exact: {
      type: Boolean,
      default: false
    },
    /**
     * If the button is to be used as a submit button
     */
    forForm: {
      type: Boolean,
      default: false
    },
    /**
     * Setting the Disabled State
     */
    isDisabled: {
      type: Boolean,
      default: false
    },
    baseLayout: {
      type: String,
      default: 'inline-flex items-center justify-center rounded'
    },
    /**
     * Basic style of the button
     */
    baseStyle: {
      type: String,
      default: 'transition-all duration-300 cursor-pointer border-0 focus:outline-none'
    },
    /**
     * Use of predefined color palettes
     * @values base, caution, carefree, transparent, grayed, ghost, info
     */
    colorPalette: {
      type: String as () => ColorOptions,
      default: 'base'
    },
    /**
     * Passing of an own color style
     */
    customColor: {
      type: String,
      default: ''
    },
    /**
     * Style when the button is locked
     */
    disabledColoring: {
      type: String,
      default: 'text-white bg-gray-300 italic cursor-not-allowed'
    },
    /**
     * Defining the size of the button
     */
    size: {
      type: String,
      default: 'py-2 px-3'
    }
  },
  setup (props) {
    const coloring = computed(() : string => {
      if (props.customColor) return props.customColor

      const matrix = {
        base: 'text-white bg-primary-regular hover:bg-primary-400',
        marketing: 'text-white bg-marketing hover:bg-marketing-400',
        grayed: 'text-white bg-gray-400 hover:bg-gray-300 ',
        lightGray: 'text-white bg-gray-300 hover:bg-gray-400',
        caution: 'bg-error-high text-white hover:bg-error-highLight',
        carefree: 'bg-error-low text-white hover:bg-error-lowLight',
        ghost: 'text-gray-700 bg-white hover:bg-gray-50 border border-gray-300',
        info: 'text-gray-700 bg-third-regular hover:bg-third-500',
        transparent: 'bg-transparent text-gray-700 hover:underline',
        gray: 'text-gray-700 bg-gray-200 hover:bg-gray-300'
      }

      return matrix[props.colorPalette]
    })

    /**
     * Creates the corresponding tag of the button
     */
    const buttonTag = computed(() : string => {
      if (props.forForm) return 'button'
      return props.linkPath
        ? props.linkPath.match(/^(http|mailto|tel)/)
          ? 'a'
          : 'nuxt-link'
        : props.customTag
    })

    return {
      buttonTag,
      coloring
    }
  }
})
