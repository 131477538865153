import { computed, useRoute, useStore } from '@nuxtjs/composition-api'
import { linkGenerator } from '~/domain/navigationTools'

export default function () {
  const store = useStore()
  const route = useRoute()

  const header = computed(() => store.getters['navigation/getNavHeader'])
  const navigation = computed(() => store.getters['navigation/getNavigation'])
  const activeNav = computed(() => store.getters['navigation/getNavPath'])
  const getUserPlan = computed(() => store.getters['access/getUserPlan'])

  const mainNavigation = computed(() => {
    const { pid, sid } = route.value.params

    const navigationEntries = ['crawler', 'overview', 'monitoring']

    return navigationEntries.reduce((acc: any, current) => {
      const entry = navigation.value.find(val => val.id === current)
      if (entry) {
        const groups = entry.groups.map((group) => {
          return {
            id: group.id,
            groupName: group.groupName,
            path: linkGenerator(group.entries[0].path, pid, sid),
            activeSub: group.entries[0].id
          }
        })[0]
        if (groups) {
          acc = [...acc, groups]
        }
      }
      return acc
    }, [])
  })

  const subNavigation = computed(() => {
    const { pid, sid } = route.value.params
    const entry = navigation.value.find(val => val.id === activeNav.value.main)
    if (!entry) return []
    const groupEntry = entry.groups.find(val => val.id === activeNav.value.group)
    if (groupEntry) {
      return groupEntry.entries
        .filter(val => !val.notInNav)
        .map((val) => {
          let hidden
          if (val.isHidden) {
            hidden = val.isHidden
          }
          return {
            id: val.id,
            name: val.name,
            path: linkGenerator(val.path, pid, sid),
            isHidden: hidden || false,
            salesLabel: val.salesLabel?.condition(getUserPlan.value) ? val.salesLabel : null
          }
        }).filter(val => !val.isHidden)
    } else {
      return []
    }
  })

  return {
    mainNavigation,
    subNavigation,
    activeNav,
    header
  }
}
