
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  // name: 'HeightExpander', // tslint:disable-line
  functional: true,
  render (createElement, context) {
    const data = {
      props: {
        name: context.props.timing ? 'expand-' + context.props.timing : 'expand-fast'
      },
      on: {
        afterEnter (element) {
          // eslint-disable-next-line no-param-reassign
          element.style.height = 'auto'
        },
        enter (element) {
          const { width } = getComputedStyle(element)
          /* eslint-disable no-param-reassign */
          element.style.width = width
          element.style.position = 'absolute'
          element.style.visibility = 'hidden'
          element.style.height = 'auto'
          /* eslint-enable */
          const { height } = getComputedStyle(element)
          /* eslint-disable no-param-reassign */
          element.style.width = null
          element.style.position = null
          element.style.visibility = null
          element.style.height = 0
          /* eslint-enable */
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            element.style.height = height
          })
        },
        leave (element) {
          const { height } = getComputedStyle(element)
          // eslint-disable-next-line no-param-reassign
          element.style.height = height
          // eslint-disable-next-line no-unused-expressions
          getComputedStyle(element).height
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            element.style.height = 0
          })
        }
      }
    }
    return createElement('transition', data, context.children)
  }
})
