
export default {
  name: 'IconArrow',

  props: {
    /**
     * Here you can rotate the icon
     */
    degree: {
      type: Number,
      default: null
    },
    /**
     * Icon color
     */
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
