
export default {
  name: 'IconCancel',
  props: {
    /**
     * Icon color
     */
    color: {
      type: String,
      default: '#505050'
    }
  }
}
