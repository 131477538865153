
export default {
  name: 'IconCaret',

  props: {
    /**
     * Rotation angle of the icon
     */
    degree: {
      type: Number,
      default: 0
    },
    /**
     * Icon color
     */
    color: {
      type: String,
      default: 'currentColor'
    },
    /**
     * Transition Timing
     */
    fast: {
      type: Boolean,
      default: false
    }
  }
}
