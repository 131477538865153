import { computed, useStore } from '@nuxtjs/composition-api'
import useRoleManagement from '@/composeables/roleManagement/roleManagement'
import useUpsellContentScreen from '@/composeables/upsellContentScreen/upsellContentScreen'

export default function () {
  const store = useStore()
  const wlConfig = computed(() => store.getters['whitelabel/getConfig'])

  const { realPartnerType } = useRoleManagement()
  const { cleverBridgeIframeUrl } = useUpsellContentScreen()

  const storeLink = computed(async () => {
    if (realPartnerType.value === 'standaloneRetail') {
      return await cleverBridgeIframeUrl()
    }
    return typeof wlConfig.value.storeUrl !== 'undefined' ? wlConfig.value.storeUrl : 'https://monitoring.platform360.io/purchase'
  })

  const generateStoreLink = async () => {
    if (realPartnerType.value === 'standaloneRetail') {
      return await cleverBridgeIframeUrl()
    }
    return typeof wlConfig.value.storeUrl !== 'undefined' ? wlConfig.value.storeUrl : 'https://monitoring.platform360.io/purchase'
  }

  return {
    storeLink,
    generateStoreLink
  }
}
