import { computed, useContext, useStore } from '@nuxtjs/composition-api'

export default function () {
  const { $amplitude } = useContext()
  const store = useStore()

  const userData = computed(() => store.getters['access/getUserDetails'])
  const threeSixtyId = userData.value.threeSixtyId

  const tracker = (eventTitle: string, message: {[key: string]: string} = {}) => {
    $amplitude
      .getInstance()
      .logEvent(eventTitle, { userId: threeSixtyId || null, ...message })
  }

  return {
    tracker
  }
}
