
export default {
  name: 'Richtext',

  props: {
    /**
     * The content as string
     */
    content: {
      type: String,
      default: ''
    }
  }
}
