
import { computed, defineComponent, ref, useStore, watch } from '@nuxtjs/composition-api'
import iCardContent from '~/domain/informationCardContent'
import InformationCardEntry from '~/components/feature/InformationCard/InformationCardEntry.vue'

export default defineComponent({
  name: 'InformationCard',
  components: { InformationCardEntry },
  setup () {
    const store = useStore()
    const currentCard = ref(0)

    const informationCards = computed(() => store.getters['ui/getInformationCards'])
    const cardTransition = computed(() => store.getters['ui/getCardTransition'])

    const nextCard = () => (currentCard.value = currentCard.value + 1)

    const finalCard = () => {
      currentCard.value = 1
      store.dispatch('ui/resetInformationCards')
    }

    watch(informationCards, () => {
      if (informationCards.value) currentCard.value = 0
    })

    return {
      informationCardContent: iCardContent,
      currentCard,
      informationCards,
      cardTransition,
      nextCard,
      finalCard
    }
  }
})
