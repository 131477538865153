import { computed, useStore } from '@nuxtjs/composition-api'

export default function () {
  const store = useStore()

  const userPlan = computed(() => store.getters['access/getUserPlan'])
  const whitelabelConfig = computed(() => store.getters['whitelabel/getConfig'])
  const partnerType = computed(() => whitelabelConfig.value.partnerType)
  const userProfil = computed(() => store.getters['access/getUserProfileData'])
  const isLicensePartner = computed(() => userProfil.value.isLicensePartner)

  const realPartnerType = computed(() => {
    if (partnerType.value === 'standalone') {
      if (isLicensePartner.value) return 'standalonePartner'
      else return 'standaloneRetail'
    }
    return partnerType.value.length ? partnerType.value : 'default'
  })

  const appHeaderModel = computed(() => {
    const matrix = {
      default: 'threeSixtyFSC',
      partner: 'wlWebsiteMonitoring',
      standalonePartner: 'standaloneThreeSixtyFSC',
      standaloneRetail: 'standaloneThreeSixtyFSC',
      platform: 'platformFullSiteCheck'
    }

    return matrix[realPartnerType.value] || matrix['default']
  })

  const newAppHeaderModel = computed(() => {
    const matrix = {
      default: 'threesixty',
      partner: 'partner',
      standalonePartner: 'standalone',
      standaloneRetail: 'standalone',
      platform: 'platform'
    }
    return matrix[realPartnerType.value] || matrix['default']
  })

  const isLicenseManagementReadMode = computed(() => {
    const matrix = {
      default: true,
      platform: true,
      partner: true,
      standaloneRetail: false,
      standalonePartner: true
    }
    return matrix[realPartnerType.value]
  })

  const isInternalUsage = computed(() => {
    return ['platform', 'default'].includes(realPartnerType.value)
  })

  const isExternalUsage = computed(() => {
    return ['partner', 'standaloneRetail', 'standalonePartner'].includes(realPartnerType.value)
  })

  const trialOrNotTrialPlan = computed(() => {
    return userPlan.value === 'lite' && userProfil.value.isTrial ? 'trial' : userPlan.value
  })

  return {
    realPartnerType,
    appHeaderModel,
    newAppHeaderModel,
    isLicenseManagementReadMode,
    isLicensePartner,
    isInternalUsage,
    isExternalUsage,
    trialOrNotTrialPlan
  }
}
