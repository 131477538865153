
import { defineComponent, watch } from '@nuxtjs/composition-api'
import IconCancel from '~/components/pure/IconCancel/IconCancel.vue'

export default defineComponent({
  name: 'ModalPorter',
  components: { IconCancel },
  props: {
    /**
     * Aim of the portal used
     */
    target: {
      type: String,
      default: 'modal-place'
    },
    /**
     * Status whether the modal was opened
     */
    openState: {
      type: Boolean,
      default: false
    },
    /**
     * Viewport background color
     */
    screenColor: {
      type: String,
      default: 'bg-trans-black-08'
    },
    /**
     * Modal background color
     */
    modalColor: {
      type: String,
      default: 'bg-white'
    },
    /**
     * Additional style
     */
    modalStyle: {
      type: String,
      default: ''
    },
    /**
     * To display the modal in the horizontal center of the viewport
     */
    middle: {
      type: Boolean,
      default: true
    },
    /**
     * Modal width
     */
    modalWidth: {
      type: String,
      default: 'container'
    },
    /**
     * This makes the contents scrollable in the modal
     */
    scrollable: {
      type: Boolean,
      default: false
    },
    /**
     * To display the modal in the vertical center of the viewport
     */
    desktopCentered: {
      type: Boolean,
      default: true
    },
    /**
     * This disables the regular close button to replace it with its own via the slots
     */
    customClose: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    /**
     * Toggle the Backdrop
     */
    const backDropToggle = () => {
      if (process.client) {
        const pageBody = document.querySelector('.pageLayout__body')
        if (props.openState) {
          document.body.classList.add('overflow-hidden', 'scrolling-touch')
          pageBody?.classList.add('pointer-events-none', 'select-none')
        } else {
          document.body.classList.remove('overflow-hidden', 'scrolling-touch')
          pageBody?.classList.remove('pointer-events-none', 'select-none')
        }
      }
    }

    /**
     * Watch for Changes on OpenState
     */
    watch(() => props.openState, () => {
      backDropToggle()
    }, { immediate: true })
  }
})
