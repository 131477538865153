import { computed, useStore } from '@nuxtjs/composition-api'

export default function () {
  const store = useStore()

  const userProfil = computed(() => store.getters['access/getUserProfileData'])

  const combinedName = computed(() => {
    const firstName = userProfil.value?.firstName || ''
    const lastname = userProfil.value?.familyName || ''
    const firstNameTrimmed = firstName.trim()
    const lastNameTrimmed = lastname.trim()
    return firstNameTrimmed.length || lastNameTrimmed.length ? [firstNameTrimmed, lastNameTrimmed].join(' ') : ''
  })

  return {
    combinedName
  }
}
